<template>
	<div>
		<v-dialog
			v-model="dialog"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="primary"
					v-bind="attrs"
					v-on="on"
					@click="openDialog"
					:class="{ hidden: isOverride }"
				>
					<v-icon> mdi-information </v-icon>
				</v-btn>
			</template>
			<v-card color="background">
				<v-toolbar color="primary">
					<v-btn icon dark @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>

					<v-toolbar-title>
						<v-avatar size="32">
							<v-img :src="guild.avatarUrl" alt="avatar" />
						</v-avatar>
						Reports for <b>{{ guild.name }}</b>
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<AdminGuildElkButton :id="guild.id" />
					<AdminGuildActionButton
						class="ml-2"
						:guild="guild"
						@refresh="getInfo"
					/>
					<v-btn class="ml-2" icon @click="getInfo" :loading="fetching">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-toolbar>
				<div v-if="fetching" class="fetching">
					<v-progress-circular
						class="mb-10 mt-3"
						:size="70"
						color="primary"
						indeterminate
					></v-progress-circular>
				</div>
				<div v-else>
					<AdminGuildReportInfo :guild="info" @refresh="getInfo" />
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import AdminGuildReportInfo from '@/views/admin/components/AdminGuildReportInfo';
import AdminGuildElkButton from '@/views/admin/components/AdminGuildElkButton';
import AdminGuildActionButton from '@/views/admin/components/AdminGuildActionButton';

export default {
	name: 'AdminGuildReportModal',
	components: {
		AdminGuildReportInfo,
		AdminGuildElkButton,
		AdminGuildActionButton,
	},
	props: {
		guild: {
			type: Object,
			required: true,
		},
		isOverride: {
			type: Boolean,
		},
	},
	data: () => ({
		dialog: false,
		fetching: false,
		info: {},
	}),
	computed: {},
	methods: {
		async openDialog() {
			this.getInfo();
		},
		async getInfo() {
			this.fetching = true;
			try {
				this.info = await this.$store.dispatch('getGuildReport', this.guild.id);
			} catch (err) {
				// TODO MODAL
				console.error(err);
			} finally {
				this.fetching = false;
			}
		},
	},
	mounted() {
		if (this.isOverride && this.guild) {
			this.openDialog();
			this.dialog = true;
		}
	},
};
</script>

<style scoped>
.fetching {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hidden {
	visibility: hidden;
}
</style>
