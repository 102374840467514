<template>
	<div>
		<v-dialog v-model="dialog" width="500">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="error" v-bind="attrs" v-on="on"> Action </v-btn>
			</template>
			<v-card color="background">
				<v-card-title>
					<v-avatar size="32" class="mr-2">
						<v-img :src="guild.avatarUrl" alt="avatar" />
					</v-avatar>
					<span
						>What do you want to do to <b>{{ guild.name }}</b></span
					>
				</v-card-title>
				<v-card-text class="pb-0 pt-1">
					<v-form ref="form" lazy-validation>
						<v-select
							v-model="action"
							:items="actionItems"
							label="Choose an action"
							:rules="required"
							outlined
							dense
						></v-select>
						<v-textarea
							v-model="reason"
							label="Reason"
							outlined
							:rules="required"
							autoGrow
						></v-textarea>
					</v-form>
				</v-card-text>
				<v-card-actions class="pb-4 pt-0">
					<span v-if="action" class="tip">
						{{ action.tip }}
					</span>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="submit"> Do it. </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'AdminGuildActionButton',
	props: {
		guild: {
			type: Object,
		},
	},
	data: () => ({
		dialog: false,
		action: undefined,
		reason: undefined,
		actionItems: [
			{
				text: 'Ban Guild',
				value: {
					id: 'ban',
					tip: 'Bans the guild from OwO Bot',
				},
			},
			{
				text: 'Ignore All Reports',
				value: {
					id: 'ignore',
					tip: "Reset the guild's report count to 0",
				},
			},
			{
				text: 'Leave Comment (No Msg)',
				value: {
					id: 'comment',
					tip: 'Leave a comment on the guild without messaging them',
				},
			},
		],
		required: [(v) => !!v || 'Cannot be empty'],
	}),
	methods: {
		async submit() {
			if (!this.$refs.form.validate()) {
				return;
			}

			const opt = {
				text: 'Are you sure you want to do this?',
				width: 400,
				bottomText: `<b>Guild:</b> ${this.guild.name}<br><b>Action:</b> ${this.action?.id}<br><b>Reason:</b> ${this.reason}`,
				buttons: [
					{
						text: 'Nvm',
						color: 'error',
						returns: false,
					},
					{
						text: 'yes. c:<',
						color: 'primary',
						returns: true,
					},
				],
			};
			const yes = await this.$modal(opt).showWarn();
			if (yes) this.handleAction();
		},
		async handleAction() {
			try {
				switch (this.action?.id) {
					case 'ban':
						await this.$store.dispatch('banGuildWithReason', {
							id: this.guild.id,
							reason: this.reason,
						});
						break;
					case 'ignore':
						await this.$store.dispatch('ignoreAllGuildReports', {
							id: this.guild.id,
							reason: this.reason,
						});
						break;
					case 'comment':
						await this.$store.dispatch('commentGuild', {
							id: this.guild.id,
							reason: this.reason,
						});
						break;
				}
			} catch (err) {
				console.error(err);
				const opt = {
					text: 'Action failed. Please contact Scoot.',
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
			const opt = {
				text: 'Success!',
				width: 400,
				bottomText: `<b>Guild:</b> ${this.guild.name}<br><b>Action:</b> ${this.action?.id}<br><b>Reason:</b> ${this.reason}`,
			};
			await this.$modal(opt).showInfo();
			this.reason = undefined;
			this.action = undefined;
			this.dialog = false;
			this.$emit('refresh');
		},
	},
};
</script>

<style scoped>
.tip {
	font-size: 14px;
	color: rgba(255, 255, 255, 0.7);
}
</style>
