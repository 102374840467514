<template>
	<v-data-table
		:headers="headers"
		:items="users"
		:items-per-page="8"
		:search="search"
		:item-class="rowBackground"
	>
		<template v-slot:top>
			<v-text-field v-model="search" label="Search" class="mx-4"></v-text-field>
		</template>
		<template v-slot:item.name="{ item }">
			<div class="user-info">
				<v-avatar size="32">
					<v-img :src="avatarUrl(item)" alt="avatar" />
				</v-avatar>
				<div class="user-info-name">
					{{ item.username }}#{{ item.discriminator }}
				</div>
			</div>
		</template>
		<template v-slot:item.action="{ item }">
			<AdminUserReportModal :user="modalSanitize(item)" />
		</template>
	</v-data-table>
</template>

<script>
export default {
	name: 'AdminGuildReportInfoUsers',
	components: {
		AdminUserReportModal: () =>
			import('@/views/admin/components/AdminUserReportModal'),
	},
	props: {
		users: {
			type: Array,
		},
	},
	data: () => ({
		headers: [
			{ text: 'User', value: 'name' },
			{ text: 'ID', value: 'discordId' },
			{ text: 'Reports', value: 'reportsAgainstCount' },
			{ text: 'Action', value: 'action' },
		],
		search: '',
	}),
	methods: {
		rowBackground(item) {
			if (item.banned) {
				return 'banned-row';
			}
			return '';
		},
		modalSanitize(user) {
			return {
				id: user.discordId,
				avatar: user.avatar,
				avatarUrl: this.avatarUrl(user),
				discriminator: user.discriminator,
				username: user.username,
				fullUsername: `${user.username}#${user.discriminator}`,
				reports: user.reportsAgainstCount,
			};
		},
		avatarUrl({ discordId, avatar, discriminator }) {
			if (avatar) {
				return `https://cdn.discordapp.com/avatars/${discordId}/${avatar}.png`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(discriminator) % 5
				}.png`;
			}
		},
	},
};
</script>

<style scoped>
.name {
	display: flex;
}

.table {
	display: flex;
	gap: 20px;
}

.count {
	flex-grow: 1;
}

.user-info {
	display: flex;
}

.user-info-name {
	padding-top: 6px;
	padding-left: 10px;
}

::v-deep .banned-row {
	background-color: rgba(255, 0, 0, 0.2);
}
</style>
